import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public screenWidth;
  public screenHeight;
  public sidebar = true;
  public url = 'https://haslab.com/services/';

  constructor(platform: Platform) {
    platform.ready().then(() => {
      this.screenHeight = platform.height();
      this.screenWidth = platform.width();
      console.log('Width: ' + platform.width(),this.screenWidth);
      console.log('Height: ' + platform.height(),this.screenHeight);
    });
  }
}
