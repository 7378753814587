import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
// import { VideoCallService } from './video-call.service';
import { ConfigService as Conf } from '../../providers/config/config.service';
import { LoadingController,Platform, MenuController } from '@ionic/angular';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  activeMenu = 'dashboard';
  customerData;
  public currentAppointmentIndex = -1;
  public loader;
  public loadingMsg = 'Please wait...';

  constructor(
    private router: Router,
    public http: HttpClient,
    public config: ConfigService,
    public conf:Conf,
    private nativeStorage: NativeStorage,
    public platform: Platform,
    public loadingController: LoadingController,
    public menu: MenuController,
    // public vshare: VideoCallService,
  ) { 
    this.platform.ready().then(()=>{
      this.getStorage('doctorData')
      .then(data=>{this.customerData=data;
      console.log("Dashboard this.shared.customerData.info",this.customerData)
      })
      .catch(e=>{
        // alert(JSON.stringify(e));
      });
    })
  }

  logout(){
    localStorage.removeItem("UserType");
    
    this.customerData = undefined;
    this.setStorage('doctorData',this.customerData)
    this.router.navigate(['/']);
    this.menu.close();
    this.conf.userType = '';
    // this.vshare.client.logout();
  }

  getRequest(url){
    return this.http.get(this.config.url + url)
  }
  postRequest(url,data?){
    return this.http.post<any>(this.config.url + url, data)
  }

  async loading(){
    this.loader = await this.loadingController.create({
      message: this.loadingMsg,
    });
    await this.loader.present();
  }


  setStorage(key,val){
    this.nativeStorage.setItem(key, val)
    .then(
      () => console.log('Stored item!'),
      error => console.error('Error storing item', error)
    );
  }
  getStorage(key){
    return this.nativeStorage.getItem(key)
  }
}
