import { Injectable } from '@angular/core';
import { NgxAgoraService, Stream, AgoraClient, ClientEvent, StreamEvent, AgoraRTC } from 'ngx-agora';
import { Platform, ModalController,ToastController } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { Sim } from '@ionic-native/sim/ngx';
import { AppLauncher, AppLauncherOptions } from '@ionic-native/app-launcher/ngx';
import { SharedDataService } from 'src/providers/shared-data/shared-data.service';
import { Router } from '@angular/router';
import { ConfigService } from 'src/providers/config/config.service';
// import { stat } from 'fs';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {
  mymobile = "";
  saved = false;
  callData;
  onCall = false;
  token = "";
  title = "Angular Video";
  localCallId = '';
  channel: any;
  remoteCalls: string[] = [];
  deviceId = '8574476056';
  isCall = false;
  public client: any;
  public clientVideo: AgoraClient;
  public uid: number;
  public uidAud: number;
  cordovaCall: any;
  audio = true;
  video = true;
  myVar;
  // startVideoCall: any;
  callRoom = '';
  localStream: Stream;

  constructor(
    public ngxAgoraService: NgxAgoraService,
    public androidPermissions: AndroidPermissions,
    private platform: Platform,
    private http: HTTP,
    private nativeStorage: NativeStorage,
    private sim: Sim,
    private appLauncher: AppLauncher,
    public shared: SharedDataService,
    public router: Router,
    public config: ConfigService,
    public modal: ModalController,
    public toastController: ToastController
  ) { 
    this.uid = Math.floor(Math.random() * 100);
    this.clientVideo = this.ngxAgoraService.createClient({ mode: 'rtc', codec: 'h264' });

    this.platform.ready().then(() => {
      // shared.getStorageData('customerData').then((val) => {
      //   if (val != null || val != undefined) {
      //     this.uidAud = +val.id + 999999;
      //     this.getUserToken();
      //   }
      //   else this.getSimData();
      //   console.log(val);
      // })
      // .catch(e=>{this.getSimData()});

      // Current above
      // **************************

      // this.getStorage('mymobile')
      // .then(data=>{
      //   this.mymobile=data;
      //   this.uidAud = +data;
      //   (this.mymobile=='')?this.getSimData():this.getUserToken();
      //   console.log(this.uidAud);
      // })
      // .catch(e=>{this.getSimData()});
      // this.getStorage('saved')
      // .then(data=>this.saved=data);
      // Added in this step to initialize the local A/V stream
        // this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: this.audio, video: this.video, screen: false });
        this.assignClientHandlers();
        
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CALL_PHONE).then(
          result => console.log('Has permission?',result.hasPermission),
          err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CALL_PHONE)
        );
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
          result => console.log('Has permission?',result.hasPermission),
          err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
        );
        this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO).then(
          result => console.log('Has permission?',result.hasPermission),
          err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO)
        );
        this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.CAMERA, this.androidPermissions.PERMISSION.CALL_PHONE, this.androidPermissions.PERMISSION.RECORD_AUDIO]);
    });
  }

  getSimData(){
    this.sim.getSimInfo().then(
      (info) => {
                  console.log('Sim info: ', info);
                  // alert(JSON.stringify(info));
                  this.deviceId = info.deviceId;
                  this.uidAud = +this.deviceId;
                  this.getUserToken();
                },
      (err) => {
        this.getUserToken();
        console.log('Unable to get sim info: ', err)
      }
    );
    
    this.sim.hasReadPermission().then(
      (info) => console.log('Has permission: ', info)
    );
    
    this.sim.requestReadPermission().then(
      () => console.log('Permission granted'),
      () => console.log('Permission denied')
    );
  }

  public initCall(userId){
    const maxCount = 60;
    let data = {
                  name: this.uid+'',//Math.floor(Math.random() * 100000000)+'',
                  count: 0,
                  state: 'init',
                  peerId: this.uidAud
                };
    this.myVar = setInterval(()=>{
      data.count++;
      if(data.count>60){
        clearInterval(this.myVar);
      }
      this.client.sendMessageToPeer(
        { text: JSON.stringify(data) }, // An RtmMessage object.
        userId+'', // The user ID of the remote user.
        ).then(sendResult => {
        console.log(data);
        if (sendResult.hasPeerReceived) {
        /* Your code for handling the event that the remote user receives the message. */
          data.state = 'ringing';
          clearInterval(this.myVar);
        } else {
          // alert("User is offline");
          clearInterval(this.myVar);
        /* Your code for handling the event that the message is received by the server but the remote user cannot be reached. */
        }
        }).catch(error => {
        /* Your code for handling the event of a message send failure. */
        console.log(error);
        });
    }, 1000);

    // this.client.on('MessageFromPeer', ({ text }, peerId) => {
    //   const data = JSON.parse(text);
    //   console.log('initCall',data);
    //   if(data.state=='answered'){
    //     this.callRoom = data.name;
    //     this.startVideoCall();
    //     clearInterval(this.myVar);
    //     this.cordovaCall.connectCall(data=>console.log(JSON.stringify(data)),error=>alert(JSON.stringify(error)));
    //   }
    //   if(data.state=='hangup'){
    //     clearInterval(this.myVar);
    //     this.cordovaCall.endCall(data=>console.log(JSON.stringify(data)),error=>alert(JSON.stringify(error)));
    //   }
    // });
  }


  startVideoCall(){
    // Added in this step to initialize the local A/V stream
    // this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: this.audio, video: this.video, screen: false });
    this.localCallId = this.callRoom;
    // alert('startVideoCall-'+this.localCallId+", uid"+this.uid);
    this.isCall = true;
    this.platform.ready().then( async () => {
      let mediaStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
      var videoSource: MediaStreamTrack = mediaStream.getVideoTracks()[0];
      var audioSource = mediaStream.getAudioTracks()[0];
      this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: this.audio, video: this.video, screen: false, audioSource: audioSource,videoSource: videoSource});
      // this.localStream.addTrack(videoSource)
      this.assignLocalStreamHandlers();
      this.assignClientHandlers();
      // alert('assignLocalStreamHandlers-'+JSON.stringify(this.localStream));
      this.initLocalStream(() => this.join(uid => this.publish(), error => this.presentToast('initLocalStream'+JSON.stringify(error))));
    });
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.setVideoEncoderConfiguration({
      // Video resolution
      resolution: {
        width: this.platform.width(),
        height: this.platform.height()
      },
      // Video encoding frame rate. We recommend 15 fps. Do not set this to a value greater than 30.
      frameRate: {
          min: 10,
          max: 30
      },
      // Video encoding bitrate.
      bitrate: {
          min: 500,
          max: 5000
      }
    });
    this.localStream.init(
      () => {
        this.localStream.play(this.localCallId);
        if (onSuccess) {
          onSuccess();
          console.log('I ahve published');
        }
      },
      err => {this.presentToast("getUserMedia failed, "+JSON.stringify(err));console.error('getUserMedia failed', err)}
    );
  }

  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    this.clientVideo.join(null, this.localCallId, this.uid+'', onSuccess, onFailure);
  }
  
  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.clientVideo.publish(this.localStream, err => this.presentToast('Publish local stream error: ' + JSON.stringify(err)));
  }

  assignLocalStreamHandlers(): void {
    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA).then(
      result => console.log('Has permission?',result.hasPermission),
      err => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA)
    );
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
      // alert('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
      // alert('accessDenied');
    });
  }


  toggleAudio = ()=>{
    this.audio = !this.audio;
    this.audio?this.localStream.unmuteAudio():this.localStream.muteAudio();
    // this.toggle();
  }
  toggleVidio = ()=>{
    this.video = !this.video;
    this.video?this.localStream.unmuteVideo():this.localStream.muteVideo();
    // this.toggle();
  }
  toggle = () =>{
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.uid, audio: this.audio, video: this.video, screen: false });
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
  }


  private assignClientHandlers(): void {
    this.clientVideo.on(ClientEvent.LocalStreamPublished, evt => {
      console.log('Publish local stream successfully');
    });

    this.clientVideo.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.clientVideo.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.clientVideo.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.clientVideo.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });


    this.clientVideo.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      this.clientVideo.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });


    this.clientVideo.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    this.clientVideo.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.clientVideo.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        // alert(`${evt.uid} left from this channel`);
        this.isCall = false;
        this.localCallId = '';
        this.localStream.close();
        this.clientVideo.leave(() => {
          console.log("Leavel channel successfully");
        }, (err) => {
          console.log("Leave channel failed");
        });
        this.cordovaCall.endCall();
        this.onCall = false;
        clearInterval(this.myVar);
        this.cordovaCall.endCall(()=>{
          // alert('call ended')
        },e=>{
          // alert(JSON.stringify(e))
        })
        if(this.config.userType=='user'){
          this.router.navigate(['home']);
        }
        else {
          this.router.navigate(['dashboard']);
        }
        // const path = this.config.userType=='user'?'home':'dashboard';
        console.log(evt);
      }
    });
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }
  getUserToken=()=>{
    this.platform.ready().then(() => {
      const query = 'id='+this.uidAud;
      this.http.get('https://haslab.com/services/agora/index.php?'+query, {}, {})
      .then(data => {
        this.token = data.data;
        console.log(data.status);
        console.log(data.data); // data received by server
        console.log(data.headers);
        this.login();
      })
      .catch(error => {
        // alert(JSON.stringify(error));
        console.log(error.status);
        console.log(error.error); // error message as string
        console.log(error.headers);
    
      });
    })
    .catch(e=>{
      console.log('platform is not ready')
    })
  }

  login(){
    const options: AppLauncherOptions = {
    }
    if(this.platform.is('ios')) {
      options.uri = 'fb://'
    } else {
      options.packageName = 'com.thirdeyeadvisory.hsl';
    }
    this.client.logout();
    // alert(this.uidAud);
    console.log(this.uidAud);
    this.client.login({ token: this.token, uid: this.uidAud+'' }).then(() => {
      console.log('AgoraRTM client login success');
      // alert('AgoraRTM client login success')
    }).catch(err => {
      console.log('AgoraRTM client login failure', err);
      // alert('login failure'+JSON.stringify(err))
    });
    this.client.on('MessageFromPeer', ({ text }, peerId) => { // text: text of the received message; peerId: User ID of the sender.
      /* Your code for handling the event of receiving a peer-to-peer message. */
      // alert(text);
      const data = JSON.parse(text);
      console.log(data)
      if(this.callData==undefined){
        this.callData = data;
        this.callData.peerId = peerId;
      }
      else if(this.callData.peerId != peerId){ 
        this.callData = data;
        this.callData.peerId = peerId;
      }
      if(!this.onCall && data.state=='init'){
        this.cordovaCall.receiveCall('HSL');
        this.appLauncher.launch(options)
        .then((canLaunch: boolean) => console.log('appLauncher is available'))
        .catch((error: any) => console.error('Facebook is not available'));
        this.onCall = !this.onCall;
      }
      else if(data.count>=60 && data.state!='answered'){
        this.cordovaCall.endCall();
        this.onCall = !this.onCall;
      }
      else if(!this.onCall && data.state == 'answered'){
        this.startVideoCall();
        this.appLauncher.launch(options)
        .then((canLaunch: boolean) => console.log('appLauncher is available'))
        .catch((error: any) => console.error('Facebook is not available'));
      }
      else if(this.onCall && data.state == 'endCall'){
        this.isCall = false;
        this.localCallId = '';
        this.localStream.close();
        this.clientVideo.leave(() => {
          console.log("Leavel channel successfully");
        }, (err) => {
          console.log("Leave channel failed");
        });
      }
      if(data.state=='answered'){
        this.callRoom = data.name;
        this.startVideoCall();
        clearInterval(this.myVar);
        this.cordovaCall.connectCall(data=>console.log(JSON.stringify(data)),error=>{
          // alert(JSON.stringify(error))
        });
      }
      if(data.state=='hanged'){
        clearInterval(this.myVar);
        this.modal.dismiss();
        this.cordovaCall.endCall(data=>console.log(JSON.stringify(data)),error=>{
          // alert(JSON.stringify(error))
        });
      }
      else if(data.state=='endcall'){this.cordovaCall.endCall();}
    });
  }

  setStorage(key,val){
    this.nativeStorage.setItem(key, val)
    .then(
      () => console.log('Stored item!'),
      error => console.error('Error storing item', error)
    );
  }
  getStorage(key){
    return this.nativeStorage.getItem(key)
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }
}
