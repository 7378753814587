import { Component, OnInit, Input } from '@angular/core';
import { VideoCallService } from '../../providers/video-call.service';
import { ModalController, NavParams } from '@ionic/angular';
import { SharedDataService } from '../../providers/shared-data.service';

@Component({
  selector: 'app-video-call',
  templateUrl: './video-call.component.html',
  styleUrls: ['./video-call.component.scss'],
})
export class VideoCallComponent implements OnInit {
  title = "Angular Video";
  mobile = "";
  @Input() id: number;

  constructor(
    public vshared: VideoCallService,
    public modal: ModalController,
    private navParams: NavParams,
    public shared: SharedDataService,
  ) { 
    console.log(this.id);
  }

  ngOnInit() {
    console.log(this.id);
    this.mobile = this.id+"";
    this.call();
    console.table(this.navParams);
  }

  endCall(){
    this.vshared.isCall = false;
    this.vshared.localCallId = '';
    this.close();
    this.vshared.localStream.close();
    this.vshared.clientVideo.leave(() => {
      console.log("Leave channel successfully");
    }, (err) => {
      console.log("Leave channel failed");
    });
    let data = this.vshared.callData;
    data.state = 'endCall';
    data.peerId = this.vshared.uidAud;
    this.vshared.client.sendMessageToPeer(
      { text: JSON.stringify(data) }, // An RtmMessage object.
      data.peerId+'', // The user ID of the remote user.
      ).then(sendResult => {
      if (sendResult.hasPeerReceived) {
      /* Your code for handling the event that the remote user receives the message. */
      this.vshared.callData = {};
      } else {
        // alert("User is offline");
      /* Your code for handling the event that the message is received by the server but the remote user cannot be reached. */
      }
      }).catch(error => {
      /* Your code for handling the event of a message send failure. */
      console.log(error);
      });
      this.vshared.remoteCalls = [];
  }

  call(){
    this.vshared.uidAud = +this.shared.customerData.info.id + 666666;
    console.log(this.vshared.uidAud);
    if(this.vshared.cordovaCall)this.vshared.cordovaCall.sendCall('Patient',12,data=>console.log(JSON.stringify(data)),error=>alert(JSON.stringify(error)));
    this.vshared.initCall(this.mobile);
    this.vshared.callRoom = this.vshared.uid+"";
    this.vshared.startVideoCall();
  }

  submit(){
    this.vshared.saved = true;
    this.vshared.uidAud = +this.vshared.mymobile;
    this.vshared.remoteCalls = [];
    this.vshared.setStorage('saved',this.vshared.saved);
    this.vshared.setStorage('mymobile',this.vshared.mymobile);
    this.vshared.getUserToken();
  }

  close(){
    this.modal.dismiss();
  }
}
