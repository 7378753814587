import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from "@angular/router";
import { VideoCallService } from './video-call.service';
import { SharedDataService } from './shared-data.service';
import { ConfigService } from '../../providers/config/config.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isCalled = false;
  constructor(
    private router: Router,
    public shared: SharedDataService,
    private storage: Storage,
    public config: ConfigService,
    private vshare: VideoCallService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // console.log(route.routeConfig.path,this.router)
      if (typeof(Storage) !== "undefined") {
        // Store
        const user = JSON.parse(localStorage.getItem("UserType"));
        // console.log(user);
        if(!user)return true;
        if(user.type=='user'){
          this.config.userType = 'user';
          this.router.navigate(["home"]);
          this.loginAgora(user.id);
          return false;
        }
        else if(user.type=='doctor'){
          this.config.userType = 'doctor';
          this.router.navigate(["dashboard"]);
          this.loginAgora(user.id);
          return false;
        }
        else return true;
      } else {
        return true;
        alert("Sorry, your mobile does not support Storage...");
      }
    this.storage.get('customerData').then((val) => {
      if (val != null || val != undefined ){
        if(val.id!=undefined) {
          this.config.userType = 'user';
          console.log(val);
          this.router.navigate(["home"]);
          this.loginAgora(val.id);
          return false;
        }
      }
    });
    if (this.shared.customerData==undefined || this.shared.customerData==null) {
      return true;
    }
    else{
      this.config.userType = 'doctor';
      this.router.navigate(["dashboard"]);
      this.loginAgora(this.shared.customerData.info.id);
      return false;
    }
    // else if(+this.shared.customerData.role_id==2 || +this.shared.customerData.role_id==4){
      // this.router.navigate(["main",'chat']);
      // return false;
    // }
    return true;
  }

  loginAgora(id){
    // console.log('loginAgora',this.isCalled);
    this.vshare.uidAud = +id + 666666;
    // this.vshare.getUserToken();
    if(!this.isCalled){
      this.vshare.getUserToken();
    }
    this.isCalled = true;
  }
}
